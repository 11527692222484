$primaryColor: #fb4570;

.appointment-container {
  padding: 5vh 8vh;
  // background-color: #1c1c1c;

  &.ant-layout {
    // background-color: #1c1c1c;
  }

  .appointment-header {
    font-size: 24px;
    font-weight: bold;
    padding: 24px;
    color: #111;
  }

  .appointment-card {
    .resched-form {
      .appointment-times {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      .time-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        text-align: center;
        margin-right: 1rem;
        margin-bottom: 1rem;
        border: 1px solid $primaryColor;
        color: $primaryColor;
        padding: 1rem;
        font-size: 1rem;
      }

      .time-selected,
      .time-button:hover {
        background-color: $primaryColor;
        color: #ffffff;
      }

      .calendar {
        margin-bottom: 1rem;

        .ant-fullcalendar {
          border: none;
          font-size: 1rem;

          table {
            height: auto;
          }
        }

        .ant-fullcalendar-calendar-body {
          padding: 0;
        }

        .ant-fullcalendar-selected-day .ant-fullcalendar-value {
          background-color: $primaryColor;
          border-radius: 50%;
        }

        .ant-fullcalendar-today .ant-fullcalendar-value {
          border-radius: 50%;
          box-shadow: 0 0 0 1px $primaryColor inset;
        }

        .ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
          width: 50%;
        }

        .calendar-control {
          font-size: 1.2rem;
          display: flex;
          justify-content: space-between;

          .date-label {
            font-weight: 600;
            margin-bottom: 1rem;
          }

          .date-control {
            margin-left: 1rem;
          }

          .date-control:hover {
            color: $primaryColor;
          }
        }
      }
    }

    .ant-input-search.ant-input-search-enter-button.ant-input-group-wrapper {
      .ant-input {
        // background-color: #1c1c1c;
      }
    }

    .ant-table-thead {
      tr {
        th {
          // background-color: white;
          // background-color: #1c1c1c;
          color: #111;

          span {
            // color: gray;
            color: #111;
          }

          &:hover {
            // background-color: #5c5c5c;
          }
        }
      }
    }

    .ant-table-column-has-actions:hover,
    .ant-table-column-has-sorters:hover,
    .ant-table-column-sort:hover {
      // background-color: #5c5c5c;
    }

    .ant-table-body {
      tr {
        // background-color: white;
        // background-color: #1c1c1c;
        color: #111;

        span {
          // color: gray;
          color: #111;
        }

        .col-action {
          span {
            color: #1155B0s;
            font-weight: 600;
            font-size: 11px;
          }
        }

        &:hover {
          // background-color: #5c5c5c;
        }
      }

      .ant-table-row:hover {
        // background-color: #5c5c5c;
      }
    }

    .ant-table-expanded-row.ant-table-expanded-row-level-1 {
      background-color: transparent;
    }

    .ant-tabs-tab {
      color: #111;
    }

    .ant-card-head,
    .ant-card-body {
      color: #111;
    }

    .ant-card-body {
      padding: 5;
    }

    .ant-fullcalendar,
    .ant-fullcalendar-value {
      color: #111;
    }

    .ant-fullcalendar-cell.ant-fullcalendar-today.ant-fullcalendar-selected-day
      .ant-fullcalendar-value {
      color: #111;
    }

    .ant-fullcalendar-content li span {
      color: #1155B0;
    }

    .col-name {
      span {
        color: #1155B0;
        font-weight: 600;
      }
    }

    .col-action {
      span {
        color: #1155B0;
        font-weight: 600;
        font-size: 11px;
      }
    }

    .btn-confirm {
      background-color: #1155B0;
      color: white;
      width: 150px;
      padding: 20px;
      line-height: 1px;
      margin: 5px 0;
    }

    .btn-cancel {
      background-color: white;
      color: #1155B0;
      width: 150px;
      padding: 20px;
      line-height: 1px;
      margin: 5px 0;
    }

    .disabled {
      opacity: 40%;
    }

    .action-content {
      display: flex;
      flex-direction: column;

      h3 {
        margin: 2px 10px;
        color: #1155B0;
        font-size: 11px;
        opacity: 0.5;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .events {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .events li {
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      font-size: 12px;
    }

    .ant-tabs-nav .ant-tabs-tab:hover {
      color: #FFBAE5;
    }

    .ant-tabs-nav .ant-tabs-tab-active {
      color: #1155B0;
    }

    .ant-tabs-ink-bar {
      background-color: #1155B0;
    }

    .ant-pagination-item-active {
      border-color: #1155B0;
    }

    .ant-pagination-item-active a {
      color: #1155B0;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #1155B0;
      border-color: #1155B0;
    }

    .ant-fullcalendar-fullscreen
      .ant-fullcalendar-month-panel-current-cell
      .ant-fullcalendar-month,
    .ant-fullcalendar-fullscreen
      .ant-fullcalendar-today
      .ant-fullcalendar-date {
      border-top-color: #1155B0;
    }

    .ant-fullcalendar-fullscreen
      .ant-fullcalendar-month-panel-selected-cell
      .ant-fullcalendar-month,
    .ant-fullcalendar-fullscreen
      .ant-fullcalendar-selected-day
      .ant-fullcalendar-date {
      background: #1155B033;
    }

    .ant-fullcalendar-fullscreen
      .ant-fullcalendar-month-panel-selected-cell
      .ant-fullcalendar-value,
    .ant-fullcalendar-fullscreen
      .ant-fullcalendar-selected-day
      .ant-fullcalendar-value {
      color: inherit;
    }

    .ant-table-expand-icon-th {
      display: none;
    }
    .ant-table-row-expand-icon-cell {
      display: none;
    }
    .ant-table-expanded-row-level-1 {
      td:nth-child(1) {
        display: none;
      }
    }
    .ant-table-expanded-row {
      background-color: white;
    }

    .expand-row-details {
      h1 {
        color: #333;
        margin: 0;
        font-weight: bold;
      }

      p {
        // color: black;
        color: #111;
        font-weight: 500;
        font-size: 12px;
        margin: 0;
      }
    }

    .expand-row-sub-details {
      color: gray;
      margin: 0;
    }
  }

  .ant-card-head .ant-tabs {
    margin-bottom: -32px;
  }
}
